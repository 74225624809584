<template>
  <div class="pt-4 sm:pt-8 lg:pt-12">
    <div
      class="mx-auto max-w-7xl px-6 lg:px-8"
      style="height: 90vh; display: flex; align-items: center"
    >
      <div class="mx-auto max-w-2xl sm:text-center">
        <div class="text-center">
          <div
            class="flex items-center justify-center mx-auto transition duration-500 ease-in-out transform hover:scale-110 m-10 w-1/2"
          >
            <img class="" :src="logoMini" alt="Lovi" />
          </div>

          <h1
            class="text-4xl font-bold tracking-tight text-lovi-blue sm:text-6xl sm:leading-10 mb-4"
          >
            Customer <span class="text-lovi-red">Love</span> {{ $t("is...") }}
          </h1>
          <h2
            class="text-2xl font-bold tracking-tight text-lovi-red sm:text-4xl h-10 sm:leading-10 mb-4"
          >
            <vue-writer
              :array="$t('texts').split(',')"
              :eraseSpeed="50"
              :typeSpeed="100"
              :delay="1000"
            />
          </h2>
          <div
            class="mt-4 flex items-center justify-center gap-x-6"
            v-if="!disabled"
          >
            <div class="w-full">
              <div>
                <label
                  for="company-website"
                  class="mt-4 block text-sm font-semibold leading-6 text-gray-900"
                  >{{ $t("1") }}</label
                >

                <div
                  class="relative mt-2 rounded-md shadow-sm"
                  style="height: 50px"
                >
                  <div
                    class="pointer-events-none text-2xl absolute inset-y-0 left-0 flex items-center pl-3 h-full"
                  >
                    <GlobeAltIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>

                  <input
                    type="text"
                    name="company-website"
                    id="company-website"
                    v-model="domain"
                    :class="{
                      'border-red-500': error,
                      'border-gray-300': !error,
                    }"
                    :disabled="disabled"
                    class="block w-full text-2xl rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lovi-red sm:leading-6 w-full h-full"
                    placeholder="web.com"
                  />
                </div>
              </div>
              <div>
                <label
                  for="company-website"
                  class="mt-4 block text-sm font-semibold leading-6 text-gray-900"
                  >{{ $t("2") }}</label
                >

                <div
                  class="relative mt-2 rounded-md shadow-sm"
                  style="height: 50px"
                >
                  <div
                    class="text-2xl pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 h-full mb-2"
                  >
                    <EnvelopeIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="email"
                    :class="{
                      'border-red-500': error,
                      'border-gray-300': !error,
                    }"
                    :disabled="disabled"
                    class="block w-full text-2xl rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lovi-red sm:leading-6 w-full h-full"
                    placeholder="you@example.com"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="text-2xl font-bold tracking-tight text-lovi-red sm:text-4xl h-10 sm:leading-10 mb-4"
            >
              <h3
                v-if="disabled && !loaded"
                class="text-2xl font-bold tracking-tight text-lovi-red sm:text-4xl sm:leading-10 my-4"
              >
              {{ $t("5") }}
              </h3>
            </div>
          </div>
          <!-- error -->
          <div class="text-red-500 text-sm font-bold mt-2" v-if="error">
            {{ error }}
          </div>
          <label
            v-if="!disabled"
            for="company-website"
            class="mt-4 block text-sm font-semibold leading-6 text-gray-900"
            >{{ $t("3") }}</label
          >

          <button
            @click="magic()"
            v-if="!disabled"
            class="mt-4 inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-lovi-red hover:bg-lovi-red focus:outline-none focus:border-lovi-red focus:shadow-outline-lovi-red transition duration-150 ease-in-out md:py-4 md:px-10 hover:scale-110 transform-gpu text-lg text-bold"
          >
            {{ $t("magic") }}
          </button>
          <button
            @click="magic()"
            v-if="disabled && !loaded"
            class="mt-10 inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-400 hover:bg-gray-400 focus:outline-none focus:border-gray-400 focus:shadow-outline-gray-400 transition duration-150 ease-in-out md:py-4 md:px-10 hover:scale-110 transform-gpu text-lg text-bold"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8z"
              ></path>
            </svg>
            {{ $t("doingMagic") }}
          </button>
          <div>
            <h3
              v-if="loaded"
              class="text-2xl font-bold tracking-tight text-lovi-red sm:text-4xl sm:leading-10 my-4"
            >
            ⚠️ {{ $t("emailSent") }}
            </h3>
            <p
              v-if="loaded"
              class="text-lg font-bold tracking-tight text-gray-500 sm:text-2xl sm:leading-10 my-4"
            >
              {{ $t("emailSentText") }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="absolute inset-x-0 top-[calc(100%-40rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
      >
        <svg
          class="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
            fill-opacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#9089FC" />
              <stop offset="1" stop-color="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import VueWriter from "vue-writer";
import logoMini from "@/assets/LoviAd.png";
import { EnvelopeIcon } from "@heroicons/vue/20/solid";
import { GlobeAltIcon } from "@heroicons/vue/20/solid";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDkUEMORoG4qiP4NMDkbJShU_cGdcaENYw",
  authDomain: "botgpt-cd2b5.firebaseapp.com",
  projectId: "botgpt-cd2b5",
  appId: "1:810266570615:web:936acff6235f12f1f27b72",
};

initializeApp(firebaseConfig);
const auth = getAuth();

export default {
  components: {
    VueWriter,
    EnvelopeIcon,
    GlobeAltIcon,
  },
  data() {
    return {
      disabled: false,
      error: "",
      email: "",
      domain: "",
      logoMini,
      emailSent: false,
      loaded: false,
      data: {},
    };
  },
  methods: {
    validateEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateDomain(domain) {
      var re = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim;
      if (!domain.includes(".")) return false;
      return re.test(domain);
    },
    validate() {
      if (this.validateEmail(this.email)) {
        if (this.validateDomain(this.domain)) {
          this.lovi(this.email, this.domain);
        } else {
          this.error = this.$t("Invalid domain");
        }
      } else {
        this.error = this.$t("Invalid email");
      }
    },
    magic() {
      this.error = "";
      this.validate();
    },
    lovi(email, domain) {
      localStorage.setItem("Lovi", {});
      this.disabled = true;
      var script = document.createElement("script");
      script.src = "https://widget.lovi.ai/lovi-widget.min.js";
      script.setAttribute("website", domain);
      script.setAttribute("lovi-id", "faUkjLECvrRRmGE2OnBwZ5PXmeM2");
      script.setAttribute("lang", navigator.language == "es" ? "es" : "en");
      script.setAttribute("email", email);
      document.body.appendChild(script);
      this.waitForLoviWidget();
    },
    // window.loviWidget is a global variable that is set when the widget is loaded and ready to use
    waitForLoviWidget() {
      this.loaded = false;
      let interval = setInterval(() => {
        if (window.loviWidget) {
          clearInterval(interval);
          setTimeout(() => {
            window.loviWidget.openChat();
          }, 3000);

          this.emailSent = true;
          this.loaded = true;
          this.data = {
            email: this.email,
            language: navigator.language == "es" ? "es" : "en",
            type: "ecommerce",
            website: window.loviWidget.website || "",
            botName: window.loviWidget.botName || "",
            botImage: window.loviWidget.botImage || "",
            widgetColor: window.loviWidget.widgetColor || "",
          };
          this.sendEmail(this.email);
        }
      }, 100);
    },
    sendEmail(email) {
      let params = new URLSearchParams(this.data).toString();
      const actionCodeSettings = {
        url: "https://dashboard.lovi.ai?" + params,
        handleCodeInApp: true,
        domain: "link.lovi.ai",
      };
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // delete all cookies with domain .lovi.ai

          document.cookie =
            "emailForSignIn=" + email + ";Domain=.lovi.ai;path=/";
          console.log("email sent");
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    },
  },
};
</script>
