import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Lovi | Try Lovi',
    component: HomeView,
    meta: { title: "Lovi | Try Lovi" }

  },
  {
    path: '/prices',
    name: 'Lovi | Prices',
    component: () => import('../views/PricesView.vue'),
    meta: { title: "Lovi | Prices" }

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Lovi'
  next()
})

export default router
