<template>
  <header class="absolute inset-x-0 top-0 z-50">
    <nav
      class="flex items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <div class="flex lg:flex-1"></div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">{{$t('Open main menu')}}</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a
          v-for="item in navigation"
          :key="item.name"
          :href="item.href"
          class="text-base font-semibold leading-6 text-lovi-blue"
          :class="{ 'text-lovi-red': item.href === $route.path }"
          >{{ item.name }}</a
        >
      </div>

      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a
          href="https://dashboard.lovi.ai/register"
          class="inline-flex items-center rounded-md bg-lovi-red px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-lovi-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lovi-red hover:scale-110 duration-150 transform-gpu"
        >
          {{ $t("signup") }}
        </a>
      </div>
    </nav>
    <Dialog
      as="div"
      class="lg:hidden"
      @close="mobileMenuOpen = false"
      :open="mobileMenuOpen"
    >
      <div class="fixed inset-0 z-50" />
      <DialogPanel
        class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Lovi</span>
            <img class="h-8 w-auto" :src="logo" alt="" />
          </a>
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = false"
          >
            <span class="sr-only">{{$t('Close menu')}}</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a
                v-for="item in navigation"
                :key="item.name"
                :href="item.href"
                class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-lovi-blue hover:bg-gray-50"
                >{{ item.name }}
              </a>
            </div>
            <div class="py-6 space-y-2">
              <a
                href="https://dashboard.lovi.ai/register"
                class="inline-flex items-center rounded-md bg-lovi-red px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-lovi-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lovi-red"
              >
                {{ $t("signup") }}
              </a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
  <router-view />
</template>

<script setup>
import { ref } from "vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import logo from "./assets/lovi RGB (pantallas) identidad_comb mix.svg";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const navigation = [
  { name: t("login"), href: "https://dashboard.lovi.ai/register" },
  { name: t("tryit"), href: "/" },
  { name: "By botslovers", href: "https://www.botslovers.com/" },
  // { name: t("pricing"), href: "/prices" },
];

const mobileMenuOpen = ref(false);
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
